import { TOAST_ID } from "./../components/Toast/type";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { POPOVER } from "../components/Toast/type";
import { IResponse } from "../types";
import { getLocaleFromLS, getUserFromLS } from "../utils/getFromJS";
import pubsub from "pubsub-js";
import { DOMAIN } from "../config";
import { isBrowser } from "../utils";

const axiosInterceptors = {
  request: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFulfilled: (config: AxiosRequestConfig) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRejected: (error: any) => {}
  },
  response: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFulfilled: (response: AxiosResponse<any>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRejected: (error: any) => {}
  }
};

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

axios.interceptors.request.use(
  (config) => {
    config.headers["locale"] = getLocaleFromLS() || "en";
    config.headers["x-cf-token"] = config.headers["x-cf-token"] || getUserFromLS()?.token || "";
    config.headers["x-device-type"] = "web";

    axiosInterceptors.request.onFulfilled(config);

    return config;
  },
  (error) => {
    // add error handling
    axiosInterceptors.request.onRejected(error);

    return Promise.reject(error);
  }
);

// interceptor response
axios.interceptors.response.use(
  (response) => {
    if (isBrowser) {
      if (
        response.data?.code &&
        !response.data?.success &&
        response.data?.message &&
        response.config.headers?.["symbol"]?.toString() !== "0"
      ) {
        toast.error(response.data?.message, { containerId: POPOVER.MESSAGE });
      }
    }

    axiosInterceptors.response.onFulfilled(response);

    return response;
  },
  (err) => {
    // add error handling
    axiosInterceptors.response.onRejected(err);

    /** return error */
    isBrowser && console.error("UIKit Axios Response", err);

    // default error handling
    if (err?.response) {
      if (isBrowser) {
        if (
          err.response?.data?.code &&
          !err.response?.data?.success &&
          err.response?.data?.message &&
          err.response.config.headers?.["symbol"]?.toString() !== "0"
        ) {
          toast.error(err.response?.data?.message, {
            containerId: POPOVER.MESSAGE,
            toastId: err.response?.data?.message,
            updateId: err.response?.data?.message
          });
        }

        switch (err.response.status) {
          case 401:
            pubsub.publish("user", null);
            break;
        }
      }

      return Promise.resolve(err.response?.data?.code ? err.response : null);
    } else {
      if (err?.config?.headers?.["symbol"]?.toString() !== "0" && isBrowser) {
        toast.error("Network error, unable to connect to server", {
          containerId: POPOVER.MESSAGE,
          toastId: TOAST_ID.REQUEST_EXCEPTION
        });
      }
    }
  }
);

/**
 *
 * @param url request url
 * @param params  request parameters
 * @param config request body configuration
 * @returns Promise<T>
 * @example const example = get("https://api.github.com/user")
 */

const get = async <T = unknown>(url: string, params = {}, config = {}) => {
  const result = await axios.get(DOMAIN?.API + url, {
    params,
    ...config
  });

  if (!result) {
    const payload: IResponse<T> = {
      code: "0001",
      event: null,
      message: null,
      success: false,
      data: result
    };
    return payload;
  }
  const { code } = result.data;
  const payload: IResponse<T> = {
    code: "0000",
    event: null,
    message: null,
    success: true,
    data: result.data
  };

  if (code) {
    return result.data as IResponse<T>;
  } else {
    return payload;
  }
};

/**
 *
 * @param url request url path -> /v2/xx/xxx
 * @param params  request parameters
 * @param config request body configuration
 * @returns Promise<T>
 * @example const example = post("https://api.github.com/user")
 */

const post = async <T = unknown>(url: string, body = {}, config = {}) => {
  const result = await axios.post(DOMAIN?.API + url, body, config);

  if (!result) {
    const payload: IResponse<T> = {
      code: "0001",
      event: null,
      message: null,
      success: false,
      data: result
    };
    return payload;
  }
  const { code } = result.data;
  const payload: IResponse<T> = {
    code: "0000",
    event: null,
    message: null,
    success: true,
    data: result.data
  };

  if (code) {
    return result.data as IResponse<T>;
  } else {
    return payload;
  }
};

/**
 *
 * @param url request url path -> /v2/xx/xxx
 * @param params  request parameters
 * @param config request body configuration
 * @returns Promise<T>
 * @example const example = remove("https://api.github.com/user")
 */

const remove = async <T = unknown>(url: string, params = {}, config = {}) => {
  const result = await axios.delete(DOMAIN?.API + url, {
    params,
    ...config
  });

  if (!result) {
    const payload: IResponse<T> = {
      code: "0001",
      event: null,
      message: null,
      success: false,
      data: result
    };
    return payload;
  }
  const { code } = result.data;
  const payload: IResponse<T> = {
    code: "0000",
    event: null,
    message: null,
    success: true,
    data: result.data
  };

  if (code) {
    return result.data as IResponse<T>;
  } else {
    return payload;
  }
};

export { axiosInterceptors, get, post, remove };
export default axios;
